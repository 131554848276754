<template>
    <section class="section-narrow">
        <div class="container">
            <h1>{{$t('invalidEmailChange.heading')}}</h1>
            <p>{{$t('invalidEmailChange.subtitle')}}</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'InvalidEmailChange'
}
</script>

<i18n>
{
    "cz": {
        "invalidEmailChange": {
            "heading": "Změna emailu nebyla úspěšná",
            "subtitle": "Odkaz pro změnu emailu je nesprávný, vypršela jeho platnost, nebo byla změna už dříve potvrzena."
        }
    },
    "en": {
    }
}
</i18n>
